


	<!-- main element for Google's javascript validations. -->
	<main class="main">
		<div class="ui_body">


			<div id="ui_verbage" class="row">
				<div class="col-12">
					<h3>Profile</h3>
					<p style="font-weight: 600;">
						Privacy guarantees your kitchen's address will be viewed 
						by the customers you invite into your kitchen.  
						If you would like your kitchen to be viewed by the 
						public, then click the button below to set your 
						kitchen address to public.  Click again to change
						it back to private.
					</p>
				</div>
			</div><!-- ui_verbage -->
		
		
			<div class="ui_outer row">


				<!-- BEGIN QUICK START INNER INTERFACE SECTION -->
				<div id="ui_inner" class="col-12">
				
					<!-- toast -->
					<div #toastElement id="toastElement" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
				
						<div class="toast-header">
							<strong class="toast_text mr-auto">Notification</strong>
							<button 
								type="button" 
								class="ml-2 mb-1 close"
								aria-label="Close"
								style="float: right;"
							>
								<span id="times" aria-hidden="true">&times;</span>
							</button>
						</div>
				
						<div class="toast-body toast_text"></div>
					</div>

					<div class="row">
						<div class="col">
							<span style="text-align: center;">
								<h2>Privacy</h2>
							</span>

							<h4 #privacyMessage></h4>
							
							<!-- Save button -->
							<span style="text-align: center;">
								<div id="form-buttons" class="form-group">
									<button id="btnUpdateProfile"
										type="submit" 
										class="btn btn-success btn-lg" 
										(click)="update();"
									>
										Change
									</button>
								</div>
							</span>
							<!-- Save button -->
							

						</div><!-- col -->

					</div><!-- row -->

				</div><!-- ui_inner -->
				<!-- END QUICK START INNER INTERFACE SECTION -->

			</div><!-- ui_outer -->

		</div><!-- ui_body -->

	</main>