<!-- ADDRESSES TABLE  ADDRESSES TABLE  ADDRESSES TABLE -->
<div style="color: black; padding: 1rem; border-radius: 25px;">
	<div class="row">
		<div class="col-12" style="border: 1px solid #00000017; border-radius: 25px; padding: 1rem; box-shadow: 3px 3px 5px #888888;">
			<div>
				<h5>{{ address.addressLine1 }}</h5>
				<h5>{{ address.addressLine2 }}</h5>
				<h6>{{ address.city }} {{ address.state }} {{ address.zip }}</h6>
				<h6>{{ address.addressType }}</h6>
			</div>

			<div class="row" style="margin-top: 1.75rem;">
				<div class="col-12">
					<div id="footer_spacing" 
						class="align-middle"
						(click)="removeAddress(address);"
						style="background-color: rgb(178,33,30);color:white;"
					>
						<span class="col-12" style="text-align: -webkit-center; text-align: center;">Remove</span>
					</div>
				</div>
			</div>
		</div>
	</div>

</div>
<!-- ADDRESSES TABLE  ADDRESSES TABLE  ADDRESSES TABLE   -->