<div class="body">

	<!-- main element for Google's javascript validations. -->
	<main>

		<div class="quickstart_body">

			<div id="qs_verbage" class="row">
				<div class="col-12">
					<h4>Menu</h4>
					Add addresses for different kitchen locations.
				</div>
			</div>


			<div class="qs_outer_interface row">

				<div id="qs_inner_interface" class="col-12">


					<!-- Container for the demo -->
					<div class="list-group">
						<div
							*ngFor="let menuItem of Menus"
							(click)="manage(menuItem.url);"
							class="{{ menuItem.classes }}"
							[ngStyle]="{'border-bottom':'1px solid #ffffff','font-size':'x-large'}"
						>

							<div id="{{ menuItem.url }}">
								<div class="card-icon" style="padding-top: 1rem; width: 50px;">
									<span>
										<i class="{{ menuItem.icon }}" aria-hidden="true" style="color: #19aa8d; font-size: xx-large"></i>
									</span>
								</div>
								<div style="margin-left: 1rem;">
									<span class="list-group-item-heading">
										{{ menuItem.heading }}
									</span>
									<p class="list-group-item-text">{{ menuItem.text }}</p>
								</div>
							</div>

						</div>

					</div>



					<!-- <ul class="list-group">
						<li class="list-group-item d-flex justify-content-between align-items-center" 
							(click)="showProfilePage('profilePrivate');"
							style="border-top-left-radius: 25px;border-top-right-radius: 25px;"
						>
							Privacy
							<i style="color: green; font-size: 1.25em;" 
								class="fas fa-chevron-right"></i>
						</li>



						<li class="list-group-item d-flex justify-content-between align-items-center" 
							(click)="showProfilePage('profile');"
						>
							Profile
							<i style="color: green; font-size: 1.25em;" 
								class="fas fa-chevron-right"></i>
						</li>



						<li class="list-group-item d-flex justify-content-between align-items-center" 
							(click)="showProfilePage('profileDelivery');"
						>
							Delivery
							<i style="color: green; font-size: 1.25em;" 
								class="fas fa-chevron-right"></i>
						</li>



						<li class="list-group-item d-flex justify-content-between align-items-center" 
							(click)="showProfilePage('profileEmailAddresses');"
						>
							Emails
							<i style="color: green; font-size: 1.25em;" 
								class="fas fa-chevron-right"></i>
						</li>



						<li class="list-group-item d-flex justify-content-between align-items-center" 
							(click)="showProfilePage('profileAddresses');"
						>
							Addresses/Locations
							<i style="color: green; font-size: 1.25em;" 
								class="fas fa-chevron-right"></i>
						</li>



						<li class="list-group-item d-flex justify-content-between align-items-center" 
							(click)="showProfilePage('profileHours');">
							Hours of Operations
							<i style="color: green; font-size: 1.25em;" 
								class="fas fa-chevron-right"></i>
						</li>



						<li class="list-group-item d-flex justify-content-between align-items-center" 
							(click)="showProfilePage('profilePhoneNumbers');">
							Phone Numbers
							<i style="color: green; font-size: 1.25em;" 
								class="fas fa-chevron-right"></i>
						</li>



						<li class="list-group-item d-flex justify-content-between align-items-center" 
							(click)="showProfilePage('profilePhotos');">
							Logo
							<i style="color: green; font-size: 1.25em;" 
								class="fas fa-chevron-right"></i>
						</li>



						<li class="list-group-item d-flex justify-content-between align-items-center" 
							(click)="showProfilePage('profileExpress');">
							Connect to Ecommerce
							<i style="color: green; font-size: 1.25em;" 
								class="fas fa-chevron-right"></i>
						</li>
					</ul> -->

				</div>

			</div>

		</div>

	<!-- MENUS MENUS MENUS -->
	</main>

</div>