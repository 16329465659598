<!-- main element for Google's javascript validations. -->
<main class="main">
	<div class="ui_body">


		<div id="ui_verbage" class="row">
			<div class="col-12">
				<h3>Profile - {{ company }}</h3>
				<p style="font-weight: 600;">
					This information is about your profile.  Fill it in.
				</p>
			</div>
		</div><!-- ui_verbage -->
	
	
		<div class="ui_outer row">

			<!-- BEGIN QUICK START INNER INTERFACE SECTION -->
			<div id="ui_inner" class="col-12">
			
				<!-- toast -->
				<div #toastElement id="toastElement" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
			
					<div class="toast-header">
						<strong class="toast_text mr-auto">Notification</strong>
					</div>
			
					<div class="toast-body toast_text"></div>
				</div>

				<div class="row">
					<div class="col">							
						
						<div id="profileUI" style="color: black; text-align: left;">
							<div><strong>First:</strong> {{ firstName }}</div>
							<div><strong>Last:</strong> {{ lastName }}</div> 
							<div><strong>Company:</strong> {{ company }}</div>
							<div><strong>Description:</strong> {{ description }}</div>
							<div><strong>Message:</strong> {{ message }}</div>
							<div><strong>Tags:</strong> {{ tagsString }}</div>
							<div><strong>Delivery?:	</strong>{{ hasDelivery }}</div>
							<div><strong>Delivery Range: </strong>{{ deliveryRange }}</div>
							<div><strong>Country: </strong>{{ country }}</div>

							<!-- Save or Cancel -->
							<div id="editButton" class="row" style="margin-top: 1.75rem;">
								<div class="col-12 text-center text-white">
									<!-- <button 
										routerLink="profileEdit" 
										class="
											btn btn-success 
											mpkButton 
											btn-block btn-lg mb-4"
									>
										Edit
									</button> -->
									<button 
										(click)="goToEdit()" 
										class="
											btn btn-success 
											mpkButton 
											btn-block btn-lg mb-4"
									>
										Edit
									</button>
								</div>
							</div>
						</div>

					</div><!-- col -->

				</div><!-- row -->

			</div><!-- ui_inner -->
			<!-- END QUICK START INNER INTERFACE SECTION -->

		</div><!-- ui_outer -->

	</div><!-- ui_body -->

</main>