<div class="body">


	<!-- main element for Google's javascript validations. -->
	<main class="main">
		<div class="ui_body">


			<div id="ui_verbage" class="row">
				<div class="col-12">
					<h3>Profile</h3>
					<p style="font-weight: 600;">
						Edit your profile information.
					</p>
				</div>
			</div><!-- ui_verbage -->
		
		
			<div class="ui_outer row">

				<!-- BEGIN QUICK START INNER INTERFACE SECTION -->
				<div id="ui_inner" class="col-12">

					<!-- toast -->
					<div #toastElement id="toastElement" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
				
						<div class="toast-header">
							<strong class="toast_text mr-auto">Notification</strong>
						</div>
				
						<div class="toast-body toast_text"></div>
					</div>

					<div class="row">
						<div class="col">							
							
							<form
								class="form mt-4" 
								[formGroup]="addForm" 
								[ngStyle]="{'text-align':'-webkit-center'}" 
								(ngSubmit)="save()"
							>
								<div style="color: black;">
									<h2>About You</h2>
									<p>Please fill in this form to update your profile.</p>
								</div>
								<hr>
								<!-- First name and Last name -->
								<!-- First Name -->
								<div class="form-group">
									<div class="input-group">
										<input type="text"
											id="firstName" 
											name="firstName"
											class="form-control"
											autofocus 
											formControlName="firstName"
											placeholder="First name..."
										>
									</div>
								</div>
								<!-- Last Name -->
								<div class="form-group">
									<div class="input-group">
										<input 
										id="lastName" name="lastName" type="text" class="form-control" 
										formControlName="lastName" 
										placeholder="Last name..."
										oninput="this.className = ''" required="required">
									</div>
								</div>
								<!-- Company -->
								<div class="form-group">
									<div class="input-group">
										<input 
										id="company" name="company" type="text" class="form-control" 
										formControlName="company"
										placeholder="Company Name" 
										oninput="this.className = ''" required="required">
									</div>
								</div>
								<!-- Display Name
								<div class="form-group">
									<div class="input-group">
										<input 
										id="displayName" name="displayName" type="text" class="form-control" 
										placeholder="Display Name"
										oninput="this.className = ''">
									</div>
								</div> -->
								<!-- Website
								<div class="form-group">
									<div class="input-group">
										<input 
										id="website" name="website" type="text" class="form-control" 
										placeholder="Website"
										oninput="this.className = ''">
									</div>
								</div> -->
								<!-- Description -->
								<div class="form-group">
									<label for="description">Description</label>
									<div class="input-group">
										<textarea
											id="description" name="description" class="form-control description"
											formControlName="description"
											rows="6"
											oninput="this.className = ''"
										>
										</textarea>
									</div>
								</div>
								<!-- Message -->
								<div class="form-group">
									<label for="message">Message</label>
									<div class="input-group">
										<input 
										id="message" name="message" type="text" class="form-control" 
										formControlName="message"
										placeholder="Welcome to my kitchen where you'll find..."
										oninput="this.className = ''">
									</div>
								</div>
								<!-- Tags -->
								<div class="form-group">
									<label for="tagsString">Tags [Use commas to tag your menu items.]</label>
									<div class="input-group">
										<input 
										id="tagsString" name="tagsString" type="text" class="form-control" 
										formControlName="tagsString"
										placeholder="(Chicken, Soul Food, Open Late, etc.)"
										oninput="this.className = ''">
									</div>
								</div>
								<!-- Has Delivery -->
								<div class="form-group">
									<div class="input-group">
										<input 
										id="hasDelivery" name="hasDelivery" type="checkbox" 
										ng-checked="Profile_hasDelivery"
										formControlName="hasDelivery"
										oninput="this.className = ''">Will you deliver?<br />
										<span style="color: grey;">My Personal Kitchen does not offer delivery.</span>
									</div>
								</div>
								<!-- Delivery Range -->
								<div class="form-group">
									<div class="input-group">
										<input 
										id="deliveryRange" name="deliveryRange" type="text" class="form-control" 
										formControlName="deliveryRange"
										placeholder="Delivery Range"
										oninput="this.className = ''">
									</div>
								</div>
								<!-- Country -->
								<div class="form-group">
									<div class="input-group">
										<input 
										id="country" name="country" type="text" class="form-control" 
										formControlName="country"
										placeholder="Country"
										oninput="this.className = ''">
									</div>
								</div>
							
								<!-- Save button -->
								<div id="form-buttons" class="form-group">
									<button id="btnSaveProfile"
										type="submit" class="btn btn-primary btn-lg" 
										ng-show="$ctrl.initialized === false"
									>
										Save
									</button>
								</div>
							</form>

						</div><!-- col -->

					</div><!-- row -->

				</div><!-- ui_inner -->
				<!-- END QUICK START INNER INTERFACE SECTION -->

			</div><!-- ui_outer -->

		</div><!-- ui_body -->

	</main>

</div>