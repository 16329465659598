<!-- main element for Google's javascript validations. -->
<main>
	<div class="col-12">
		<h4>Your Ecommerce Setup</h4>
		Set up your ecommerce with Stripe.com.  MyPersonalKitchen will not have access to your Stripe account.
	</div>

	<ul class="list-group" style="color: black;">

			<li class="list-group-item d-flex justify-content-between align-items-center" style="border-top-left-radius: 25px; border-top-right-radius: 25px;">
				Stripe.com is a subsidiary of Wells Fargo.  Your account information will be stored with the Wells Fargo Financial Institution.
			</li>



			<li class="list-group-item d-flex justify-content-between align-items-center">
				My Personal Kitchen will never have access to your account information.  All payment activity is handled exclusively by Stripe.com through Wells Fargo.<br /><br /> 
			</li>



			<li class="list-group-item 
				d-flex 
				justify-content-between 
				align-items-center"
				style="padding-left: 15vw; padding-right: 15vw;"
			>
				<a href="https://dashboard.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_GnR7YRsCXElNVezRDCFwrnDGEyM5ytWU&scope=read_write&redirect_uri=https://www.mypersonalkitchen.com/#!/expressmanager?state=0123456789" 
					class="btn btn-success"
					style="padding: 1rem;"
				>
					<span>Setup Ecommerce Account</span>
				</a>
			</li>

	</ul>
</main>