<div class="body">

	<!-- main element for Google's javascript validations. -->
	<main>

		<div class="quickstart_body">
	
			<div class="col-12 format">

				<h2>Locations</h2>

				<p>Add a new location</p>

				<hr>
			
				<!-- toast -->
				<div #toastElement id="toastElement" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
			
					<div class="toast-header">
						<strong class="toast_text mr-auto">Notification</strong>
					</div>
			
					<div class="toast-body toast_text"></div>
				</div>


				<form [formGroup]="form" (ngSubmit)="onSubmit()">
					<!-- Address 1 -->
					<div class="row my-3">
						<div class="col-12">
							<input #addressLine1 name="addressLine1" type="text"
								class="form-control" 
								formControlName="addressLine1" 
								placeholder="Location address Line 1" 
								required="required" autocomplete="address line 1"
							/>
						</div>
					</div>


					<!-- Address 2 -->
					<div class="row my-3">
						<div class="col-12">
							<input
								#addressLine2 name="addressLine2" type="text" 
								class="form-control"
								formControlName="addressLine2" 
								placeholder="Location address Line 2" 
								autocomplete="address line 2"
							/>
						</div>
					</div>


					<!-- City -->
					<div class="row my-3">
						<div class="col-12">
							<input
								id="city" name="city" type="text" 
								class="form-control"
								formControlName="city" 
								placeholder="City" 
								autocomplete="city"
							/>
						</div>
					</div>


					<!-- State -->
					<div class="row my-3">
						<div class="col-12">
							<input
								id="state" name="state" type="text" 
								class="form-control"
								formControlName="state" 
								placeholder="State" 
								autocomplete="state"
							/>
						</div>
					</div>


					<!-- zip -->
					<div class="row my-3">
						<div class="col-12">
							<input
								id="zip" name="zip" type="text" 
								class="form-control"
								formControlName="zip" 
								placeholder="zip" 
								autocomplete="zip"
							/>
						</div>
					</div>


					<!-- addressType -->
					<div class="row my-3">
						<div class="col-12">
							<input
								id="addressType" name="addressType" type="text" 
								class="form-control"
								formControlName="addressType" 
								placeholder="Location Type" 
								autocomplete="addressType"
							/>
						</div>
					</div>






					<div class="form-group">
						<div class="row" style="margin-top: 1.75rem;">
							<div class="col-12">
								<input type="submit" class="align-middle" />
							</div>
						</div>
					</div>
				</form>

			</div>

		</div>

	</main>

</div>