<div class="body">

	<!-- main element for Google's javascript validations. -->
	<main>

		<div class="quickstart_body">

			<div id="qs_verbage" class="row">
				<div class="col-12">
					<h4>Addresses</h4>
					Add addresses for different kitchen locations.
				</div>
			</div>


			<div class="qs_outer_interface row">

				<div id="qs_inner_interface" class="col-12 side_padding">

					<!-- toast -->
					<div #toastElement 
						id="toastElement" 
						class="toast" 
						role="alert" 
						aria-live="assertive" aria-atomic="true"
					>					
						<div class="toast-header">
							<strong class="toast_text mr-auto">Notification</strong>
						</div>
				
						<div class="toast-body toast_text"></div>
					</div>

					<button class="btn btn-success circleButton" 
						routerLink="/profile/addAddress">
						<i class="fas fa-plus" 
							aria-hidden="true" 
							style="color: forestgreen; font-size: 36px;"
						></i>
					</button>
			
					<span *ngIf="Addresses.length > 0">
						<div id="addressesList" class="col-12" 
							*ngFor="let address of Addresses">
							<app-address-list-item 
								[address]="address" 
								(onDelete)="removeAddress($event)"
							></app-address-list-item>
						</div>
					</span>

				</div>

			</div>

		</div>

	<!-- EMAILS EMAILS EMAILS -->
	</main>

</div>